@import './src/assets/styles/_variables.scss'
/* You can add global styles to this file, and also import other style files */

// add and override colors from theme-blue-orange-light.css here instead, to avoid getting stuck in browser cache

.content-section
    display: block
    padding: 0.4em

.p-t-1
    margin-top:10px

.p-b-1
    margin-bottom:10px

.p-b-2
    margin-bottom:20px

.p-card
    width: 90%
    max-width:680px
    min-width:280px

.no-header .p-panel-titlebar
    display: none

.p-calendar
    width: 100%

body .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button
    padding: .429em

body .p-progressbar .p-progressbar-value
    background: var(--infoColor)

.p-float-label>input:focus ~ label, .p-float-label>input.p-state-filled ~ label, .p-float-label>.p-inputtext ~ label, .p-float-label>.p-inputwrapper-focus ~ label, .p-float-label>.p-inputwrapper ~ label, .p-float-label>.p-inputwrapper-filled ~ label
    color: var(--fieldLabelColor)

.unselectable
    -webkit-touch-callout: none
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

.cursor-link
    cursor: pointer

.login-body .login-panel .right-side
    padding: 40px 0

.login-body .login-panel .p-col-12
    padding: 5px 30px 5px 30px

app-datagrid, p-table
    width: 100%
    .s-client-hold
        color: var(--errorColor)
        a, a:hover, a:visited, a:active
            color: var(--errorColor)
    .s-client-inactive
        color: var(--dropdown-placeholder-color)
        a, a:hover, a:visited, a:active
            color: var(--dropdown-placeholder-color)

a:focus
    text-decoration: underline

.s-vehicle-ineligible
    color: var(--errorColor)

.s-vehicle-ineligble-not-qualified:after
    content: "(Not Qualified)"

.s-vehicle-ineligble-on-hold:after
    content: "(On Hold)"

.s-vehicle-ineligible-grid
    color: var(--errorColor)
    font-weight: 350
    background: var(--actionBGColor) !important
    app-router-link:before
        content: "❗"


.old-mobile-app-version
    color: var(--errorColor)

.p-overlaypanel.s-dispatch-overlay-panel
    .p-overlaypanel-content
        padding: 0
        .p-toolbar
            padding: 0.25em
            flex-wrap: nowrap
            .p-button
                color: var(--blackColor)
                &.s-dispatch-overlay-selected
                    border-width: 4px
                    font-weight: bold
                &:enabled:focus
                    background-color: inherit
                &:enabled:hover
                    color: inherit
                    background-color: var(--neutralColor)
                &.p-button-success
                    background: var(--successBGColor)
                    border-color: var(--successColor)
                &.p-button-warning
                    background: var(--warnBGColor)
                    border-color: var(--warnColor)
                &.p-button-error
                    background: var(--errorBGColor)
                    border-color: var(--errorColor)
    .m-dispatch-overlay-phone
        padding: .25em
    .m-dispatch-overlay-jumpto
        padding: .5em
        .p-dropdown
            width: 17.39em

.m-dispatch-notes-details
    padding: .5em 1em 1em 1em
    > h5
        margin: .5em 0 .25em 0
    > div.note-timestamp
        text-align: right

.m-quickassign
    .s-padded-bottom
        padding-bottom: .5em
    .s-nomargin-bottom
        margin-bottom: 0
    .s-inset-scroll
        overflow-y: auto
        border-width: 2px
        border-style: inset
    .m-quickassign-unused
        height: 8em
    .m-quickassign-row
        border-bottom: 1px solid var(--borderLightColor)
        padding: .125em
        &.s-quickassign-row-empty
            font-style: italic
            background-color: var(--warnBGColor)
            color: var(--warnColor)
            padding: 1em
            text-align: center
    .m-quickassign-analysis > .m-quickassign-row
        white-space: pre-wrap
    .m-quickassign-stats
        font-weight: 500
        padding-bottom: .5em
    .m-quickassign-stat-label
        text-align: right
    table
        width: 100%
    .m-modal-progress
        display: grid
        align-items: center

.l-flex.l-flex.l-flex
    display: flex
    flex: 1

.m-assignments-detail > .p-dialog-content
    display: flex
    flex-flow: row nowrap
    align-items: stretch
    > div
        overflow-y: auto
        overflow-x: hidden
    .m-assignments-detail-left
        flex: 0 1 30em
        min-width: 18em
    .m-assignments-detail-right
        flex: 1 1 30em
        padding: .25em .5em
        display: flex
        .p-tabview,
        .p-tabview > .p-tabview-panels,
        .p-tabview p-tabpanel:first-of-type:not([hidden]),
        .p-tabview .p-tabview-panel:not([hidden])
            display: flex
            flex: 1 1 auto
            align-items: stretch
        .p-tabview,
        .p-tabview > .p-tabview-panels
            flex-flow: column nowrap
        .p-tabview-panels
            padding: 0
        .p-tabview p-tabpanel:first-of-type:not([hidden]) .p-tabview-panel
            position: relative
            > *
                width: 100%
        .m-assignments-detail-signoffs
            padding: .5em

body
    min-width: 500px
    overflow-y: hidden
    
    .p-overlaypanel.m-assignment-schedule-overlay
        background: var(--whiteSlightlyTransparentColor)
        .p-overlaypanel-content
            width: 25em
            .m-assignment-schedule-overlay-label
                font-weight: 500
                margin-bottom: .5em
            .p-col-4, .p-col-8
                margin-top: .5em
            .m-assignment-schedule-overlay-problem
                padding-top: 1em
                color: var(--errorColor)
            .m-assignment-schedule-overlay-edit
                margin-top: 1em
    
    .m-table-cell-overlay
        .p-overlay-panel-content
            display: flex
            flex-flow: column nowrap
            align-items: stretch
            max-width: 24em
        .cell-overlay-row
            position: relative
            padding: 1.5em 1em
            display: flex
            align-items: center
            gap: .62em
            &:not(:first-child)
                border-top: 1px solid var(--borderLightColor)
            > :last-child
                flex-grow: 1
                display: flex
                flex-flow: column
                gap: .25em
                min-width: 5em
            > i
                min-width: 1em
            label
                position: absolute
                top: .62em
                left: 6em
                font-size: 78%
                opacity: 62%
            .cell-overlay-value
                font-size: 125%
                white-space: pre-line
            .cell-overlay-command
                display: flex
                gap: .25em

    .p-overlaypanel.m-dispatch-legs-overlay
        min-width: 30em
        max-width: calc(100vw - 8rem)
        min-height: 5em
        transition: width .3s, height .3s
        overflow-x: scroll
        &.overlay-slim-anchored
            max-width: calc(100vw - 325px)

    .m-task-list-tasks
        width: 30em
        max-width: 33vw
        max-height: 38vh
        overflow-y: auto
        display: flex
        flex-flow: column nowrap
        gap: .25em
        > .m-task-list-task
            border: 1px solid var(--borderLightColor)
            border-radius: 1em
            margin: .1em
            padding: .5em
            > div
                display: flex
                gap: .5em
            .m-task-list-label
                font-weight: 500
                flex: 1 1 1em
                white-space: nowrap
                text-overflow: ellipsis
                overflow: hidden
            .p-progressbar
                margin: .25em

    .search-filter-text
        position: relative
        input
            padding: 6px 6px 6px 20px
        i
            position: absolute
            left: .33em
            top: .66em
            margin-top: 0

.p-datatable .p-datatable-tbody > tr > td.p-element.s-grid-highlight
    background-color: var(--scheduleNewShiftColor)

.s-ellipsis
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden

.s-strikethrough
    text-decoration: line-through
.s-error-fg.s-error-fg.s-error-fg.s-error-fg
    color: var(--errorColor)
.s-warn-fg.s-warn-fg.s-warn-fg.s-warn-fg
    color: var(--warnColor)
.s-action-fg.s-action-fg.s-action-fg.s-action-fg
    color: var(--actionColor)
.s-success-fg.s-success-fg.s-success-fg.s-success-fg
    color: var(--successColor)
.s-info-fg.s-info-fg.s-info-fg.s-info-fg
    color: var(--infoColor)
.s-inactive-fg.s-inactive-fg.s-inactive-fg.s-inactive-fg
    color: var(--inactiveColor)
.s-black-fg
    color: var(--blackColor)

// beetlejuice beetlejuice beetlejuice (incantation to raise selector priority)
.s-error-bg.s-error-bg.s-error-bg
    background: var(--errorBGColor)
.s-warn-bg.s-warn-bg.s-warn-bg
    background: var(--warnBGColor)
.s-action-bg.s-action-bg.s-action-bg
    background: var(--actionBGColor)
.s-success-bg.s-success-bg.s-success-bg
    background: var(--successBGColor)
.s-info-bg.s-info-bg.s-info-bg
    background: var(--infoBGColor)
.s-light-gray-bg.s-light-gray-bg.s-light-gray-bg
    background: var(--lightGrayColor)
.s-inactive-bg.s-inactive-bg.s-inactive-bg
    background: var(--inactiveColor)
.s-primary-dark-bg.s-primary-dark-bg.s-primary-dark-bg
    background: var(--primaryDarkerColor)

.s-error-fg-bg
    background: var(--errorColor)
.s-warn-fg-bg
    background: var(--warnColor)
.s-action-fg-bg
    background: var(--actionColor)
.s-success-fg-bg
    background: var(--successColor)
.s-info-fg-bg
    background: var(--infoColor)

.s-error-icon-before:before
    color: var(--errorColor)
    content: '❗ '
.s-warn-icon-before
    color: var(--warnColor)
    content: '⚠️ '
.s-action-icon-before
    color: var(--actionColor)
    content: '👀 '
.s-success-icon-before
    color: var(--successColor)
    content: '✔️ '
.m-datagrid-text.s-icon-before
    display: flex

.s-placeholder.s-placeholder.s-placeholder
    &, &::placeholder
        color: var(--dropdown-placeholder-color)
        font-weight: 300
    .p-placeholder
        opacity: 1

.p-placeholder.p-placeholder.p-placeholder
    color: var(--inactiveColor)
    font-weight: 300

ul.undecorated
    list-style: none
    padding-left: 0

i.i-progress-33
    background-image: url('assets/layout/icons/progress-33.svg')
    background-size: cover
    display: inline-block
    width: 5em
    height: 1.5em

i.i-progress-67
    background-image: url('assets/layout/icons/progress-67.svg')
    background-size: cover
    display: inline-block
    width: 5em
    height: 1.5em

i.i-progress-100
    background-image: url('assets/layout/icons/progress-100.svg')
    background-size: cover
    display: inline-block
    width: 5em
    height: 1.5em

.s-grayscale
    filter: grayscale(1)

.s-icon-badge
    position: absolute
    bottom: .25em
    right: .25em
    font-size: 62%

.m-gmap-iconpin
    position: relative
    > i
        font-size: 300%
    > span
        display: inline-block
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        color: var(--whiteColor)

.s-inverse-section-headers
    h4
        margin-top: .75em
        margin-bottom: .25em
        font-size: 1.17em
        > span
            display: block
            color: var(--primaryDesaturatedColor)
            background-color: var(--neutralColor)
            padding: .5em .25em

// app-antenna-strength-icon ring background matching the item background
body .p-dropdown-panel
    color: var(--darkGrayColor)
    background-color: var(--whiteColor)
    fill: var(--whiteColor)
    .p-dropdown-items .p-dropdown-item.p-highlight
        background-color: var(--highlightBgColor)
        fill: var(--highlightBgColor)

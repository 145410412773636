@import './src/assets/styles/_variables.scss';

@font-face {
    font-family: 'Liberation Mono';
    font-style: normal;
    font-weight: 400;
    src:
        local('Liberation Mono'),
        url('assets/layout/fonts/liberation.woff') format('woff');
}


@font-face {
    font-family: "rbcustom";
    src: url('assets/layout/fonts/rbcustom.eot?t=1738164511557'); /* IE9*/
    src: url('assets/layout/fonts/rbcustom.eot?t=1738164511557#iefix') format('embedded-opentype') /* IE6-IE8 */,
    url('assets/layout/fonts/rbcustom.woff2?t=1738164511557') format('woff2'),
    url('assets/layout/fonts/rbcustom.woff?t=1738164511557') format('woff'),
    url('assets/layout/fonts/rbcustom.ttf?t=1738164511557') format('truetype'),
    url('assets/layout/fonts/rbcustom.svg?t=1738164511557') format('svg');
}
  
[class^="rbcustom-"], [class*=" rbcustom-"] {
    font-family: 'rbcustom' !important;font-size: 12px;
    font-style:normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
.rbcustom-concave-hex:before { content: "\ea01"; width: 4em; }
$rbcustom-concave-hex: '\ea01';

@font-face {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 400;
    src:
        local('Sofia Pro'),
        url('assets/layout/fonts/SofiaPro.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: italic;
    font-weight: 400;
    src:
        local('Sofia Pro'),
        url('assets/layout/fonts/SofiaPro-Italic.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 250;
    src:
        local('Sofia Pro'),
        url('assets/layout/fonts/SofiaPro-ExtraLight.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 250;
    src:
        local('Sofia Pro'),
        url('assets/layout/fonts/SofiaPro-UltraLight.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: italic;
    font-weight: 250;
    src:
        local('Sofia Pro'),
        url('assets/layout/fonts/SofiaPro-ExtraLightItalic.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: italic;
    font-weight: 250;
    src:
        local('Sofia Pro'),
        url('assets/layout/fonts/SofiaPro-UltraLightItalic.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 300;
    src:
        local('Sofia Pro'),
        url('assets/layout/fonts/SofiaPro-Light.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: italic;
    font-weight: 300;
    src:
        local('Sofia Pro'),
        url('assets/layout/fonts/SofiaPro-LightItalic.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 500;
    src:
        local('Sofia Pro'),
        url('assets/layout/fonts/SofiaPro-Medium.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: italic;
    font-weight: 500;
    src:
        local('Sofia Pro'),
        url('assets/layout/fonts/SofiaPro-MediumItalic.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 600;
    src:
        local('Sofia Pro'),
        url('assets/layout/fonts/SofiaPro-SemiBold.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: italic;
    font-weight: 600;
    src:
        local('Sofia Pro'),
        url('assets/layout/fonts/SofiaPro-SemiBoldItalic.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 700;
    src:
        local('Sofia Pro'),
        url('assets/layout/fonts/SofiaPro-Bold.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: italic;
    font-weight: 700;
    src:
        local('Sofia Pro'),
        url('assets/layout/fonts/SofiaPro-BoldItalic.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 900;
    src:
        local('Sofia Pro'),
        url('assets/layout/fonts/SofiaPro-Black.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: italic;
    font-weight: 900;
    src:
        local('Sofia Pro'),
        url('assets/layout/fonts/SofiaPro-BlackItalic.woff') format('woff');
    font-display: swap;
}

:focus {
    outline: 0px;
}

body {
    margin: 0;
    font-family: 'Sofia Pro', 'Helvetica Neue', sans-serif;
    font-weight: 300;

    .p-component,
    .p-component .p-component {
        font-family: 'Sofia Pro', 'Helvetica Neue', sans-serif;
    }

    .p-breadcrumb {
        margin: 0;
        background-color: transparent;
        padding: 1em 0;
        border-width: 0 0 0px;
        color: var(--bright-text-color);

        ol {
            li,
            li:first-child,
            li.p-breadcrumb-chevron {
                color: var(--bright-text-color);

                a,
                .p-menuitem-link {
                    color: var(--bright-text-color);
                }
            }
        }
    }

    p-breadcrumb {
        flex: 1 1 auto;
    }

    .p-dialog {
        width: 500px;

        .p-dialog-footer {
            display: flex;
            justify-content: flex-start;
            flex-direction: row-reverse;
            gap: 0.5em;
        }
    }

    .p-datatable {
        .p-datatable-thead,
        .p-datatable-tfoot,
        .p-treetable-thead,
        .p-treetable-tfoot {
            > tr {
                > th {
                    padding: 0.571em 0.857em;
                    font-weight: 700;
                    border-width: 0px 1px 1px 1px;
                    border-style: solid;
                    border-color: var(--borderLightColor);
                    color: var(--textColor);
                    background-color: var(--tableHeaderColor);

                    &:first-child {
                        border-left-width: 0;
                    }

                    &:last-child {
                        border-right-width: 0;
                    }
                }
            }
        }

        .p-datatable-tbody,
        .p-treetable-tbody {
            > tr {
                > td {
                    border-width: 0px 1px 1px 1px;
                    border-style: solid;
                    border-color: var(--borderLightColor);
                    padding: 0.5em 1em;

                    &:first-child {
                        border-left-width: 0;
                    }

                    &:last-child {
                        border-right-width: 0;
                    }
                }

                &:last-child {
                    > td {
                        border-bottom-width: 0;
                    }
                }
            }

            > tr.p-highlight,
            > tr:nth-child(even).p-highlight {
                background-color: var(--selectedColor);
            }
        }

        .p-datatable-wrapper,
        .p-datatable-scrollable-wrapper,
        .p-treetable-wrapper,
        .p-treetable-scrollable-wrapper {
            border: 1px solid var(--borderLightColor);
        }
    }

    .p-datatable-scrollable-header,
    .p-treetable-scrollable-header {
        background-color: var(--tableHeaderColor);
    }

    .p-dataview {
        .p-dataview-header {
            border: 1px solid var(--borderLightColor);
            color: var(--textColor);
            background-color: var(--infoBGColor);
        }

        .p-dataview-content {
            border: 1px solid rgba(0, 0, 0, 0.15);
        }
    }

    .p-dataview-header .p-dropdown-label,
    .m-table-header .p-dropdown-label {
        text-align: left;
    }

    .p-tabview {
        &.p-tabview-top .p-tabview-nav li {
            border: 1px solid var(--borderLightColor);
            background-color: var(--neutralColor);
        }

        .p-tabview-panels {
            padding: 1em 1em 1em;
        }
    }

    .p-button {
        color: var(--textColor);
        background-color: var(--actionColor);
        border: 1px solid var(--borderLightColor);

        &:enabled:focus {
            color: var(--whiteColor);
            background-color: var(--primaryColor);
        }

        &.p-button-secondary {
            color: var(--textColor);
            background-color: var(--actionBGColor);
            border: 1px solid var(--borderLightColor);

            &:enabled:focus {
                color: var(--whiteColor);
                background-color: var(--warnColor);
            }
        }

        &.p-button-icon-only:not(.p-inputnumber-button-up):not(.p-inputnumber-button-down) {
            height: 2.357em;

            &.p-button-rounded {
                border-radius: 50%;
            }
        }
    }

    .p-paginator {
        padding: 0.571em 0;

        .p-dropdown {
            width: auto;
        }

        .p-paginator-pages {
            .p-paginator-page.p-highlight {
                background-color: var(--primaryColor);
                color: var(--whiteColor);
            }
        }
    }

    .p-datepicker {
        display: initial;
    }

    .p-inputtext,
    .p-dropdown,
    .p-multiselect {
        width: 100%;
        max-width: 100%;
        min-width: auto;
        min-height: 1.858em;
    }

    .p-dropdown .p-inputtext {
        border: none;
        border-right: 1px solid #a6a6a6;
    }

    textarea.p-inputtext {
        min-height: 5em;
    }

    input[type='color'] {
        height: 2.8em;
    }

    .p-float-label {
        border: 1px solid var(--borderLightColor);
        margin: 0;
        padding: 0px 0px 0px;

        .p-inputtext,
        .p-multiselect {
            border: 0 solid;
            background-color: transparent;
        }

        .p-inputtext,
        .p-multiselect-label {
            padding-top: 1.429em;
            display: flex;
            flex: 1 1 1em;
        }

        .p-dropdown {
            border: 0 solid;
            background-color: transparent;
        }

        .ng-touched.ng-invalid {
            border-color: var(--errorColor);
            background-color: var(--errorBGColor);

            > * {
                background-color: var(--errorBGColor);
            }
        }

        &:focus-within {
            border: 1px solid var(--inputFocusBorderColor);
        }

        > input:focus ~ label,
        > input ~ label,
        > input.p-filled ~ label,
        > .p-inputtext ~ label,
        > .p-inputwrapper-focus ~ label,
        > .p-inputwrapper ~ label,
        > .p-inputwrapper-filled ~ label,
        > textarea:focus ~ label,
        > textarea ~ label,
        > textarea.p-filled ~ label,
        > p-inputnumber > .p-inputwrapper-filled ~ label {
            top: 10px;
            left: 6px;
        }

        > p-slider ~ label {
            top: -10px;
            left: 6px;
        }

        > label,
        > textarea ~ label {
            left: 0.5em;
        }

        &.p-field-readonly {
            border: 0;
            display: flex;
            align-items: center;
            padding: 10px 0.5em;
            justify-content: flex-start;
            background-color: var(--readonlyColor);
            flex-wrap: nowrap;

            > input.p-filled ~ label {
                top: auto;
                left: auto;
                font-size: 14px;
                position: relative;
                flex: 0 0 auto;
                margin-top: 0;
                order: 0;
                color: var(--darkGrayColor);
                line-height: normal;
            }

            &.d-input-money .p-inputtext {
                flex: 1 1 auto;
                width: auto;
                padding: 0;
                order: 1;
                margin-left: 1.5em;
            }
            .p-inputtext {
                flex: 1 1 auto;
                width: auto;
                padding: 0;
                order: 1;
                margin-left: 1.5em;
            }
        }

        &.d-input-money {
            display: flex;
            align-items: baseline;
            flex-wrap: nowrap;
            gap: 0.5em;
            padding: 0 0.5em;

            .p-inputtext,
            p-inputnumber {
                flex: 1 1 auto;
                padding-left: 0;
            }
        }

        &.d-input-money-readonly {
            border: 0;
            display: flex;
            align-items: center;
            padding: 15px 0.5em 5px;
            justify-content: flex-start;
            background-color: var(--readonlyColor);
            flex-wrap: nowrap;

            &.p-inputtext {
                flex: 1 1 auto;
                width: auto;
                order: 1;
            }
        }
    }

    @supports (-moz-appearance: meterbar) {
        .p-float-label-dropdown {
            height: 3.214em;
            overflow: hidden;
        }
    }

    .p-float-label-dropdown {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        p-dropdown,
        p-multiselect {
            flex: 1 1 auto;
            width: 100%;
        }
    }

    .p-dropdown.p-dropdown-clearable .p-dropdown-label {
        padding-right: 2.5em;
    }

    .p-dropdown-clear-icon {
        top: 1.35em;
        right: 2em;
        font-size: 1em;
        transform: translate(0, -0.8em);
        padding: 0.1em;
        border: 1px solid var(--errorColor);
        background: var(--errorBGColor);
    }

    .p-inputtext {
        &.ng-dirty {
            border-color: var(--borderLightColor) !important;
            /* disable the red border around edited (not validated) fields */
        }
    }

    .p-chips {
        > ul.p-inputtext {
            .p-chips-token {
                background-color: var(--chips-token-background-color);
                color: var(--bright-text-color);
                gap: 0.5em;
            }
        }
    }

    .p-multiselect-chip {
        .p-multiselect-label {
            display: flex;
            gap: 0.25em;

            .p-multiselect-token {
                font-size: 14px;
                padding: 0.2145em 0.429em;
                margin: 0 0.286em 0 0;
                display: inline-flex;
                background-color: var(--infoColor);
                color: var(--whiteColor);
                gap: 0.5em;
            }
        }
    }

    .p-inputgroup {
        width: 100%;

        button.p-button.p-component.p-button-icon-only {
            margin-left: 0;
            position: absolute;
            right: 0.429em;
            top: 0.429em;
            font-size: 14px;
        }

        .p-float-label {
            width: 100%;

            &:last-child .p-inputtext {
                border-right-width: 0px;
            }
        }
    }

    .p-inputnumber {
        width: 100%;
    }

    .p-calendar.p-calendar-w-btn {
        .p-datepicker-trigger.p-button {
            font-size: 14px;
            top: 0.429em;
            right: 0.429em;
            height: auto;
        }
    }

    .p-datepicker-month,
    .p-datepicker-year {
        border: 1px solid var(--borderLightColor);
        padding: 0.25em;
        margin: 0 0.5em;

        &:hover {
            background: var(--actionColor);
        }
    }

    .p-monthpicker-month,
    .p-yearpicker-year {
        padding: 0.5em;
    }

    p-inputmask {
        display: flex;
    }

    p-inputmask.p-inputtext.p-component {
        padding: 0;
    }

    div[role*='switch'] {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5em;
    }

    .p-chips {
        display: block;

        > ul.p-inputtext {
            padding-top: 1.429em;
            display: block;
        }
    }

    .p-chips-in-grid {
        .p-chips {
            > ul.p-inputtext {
                padding: 0.5em;
            }
        }
    }

    .m-chipselect-wrapper {
        display: flex;
        align-items: baseline;
        gap: 0.5em;

        > p-chips {
            flex: 1 1 auto;
        }
    }

    .p-inputswitch-label {
        order: 0;
        flex: 1 1 auto;
    }

    div[role='switch'] app-info-tip > p-button {
        order: 2;
    }

    .m-inputswitch-wrapper {
        order: 1;
        flex: 0 0 auto;
        display: flex;
        align-items: center;
    }

    p-inputswitch {
        display: flex;
        align-items: center;
    }

    .p-inputswitch,
    .p-inputswitch.p-inputswitch-focus,
    .p-inputswitch:hover,
    .p-inputswitch:not(.p-disabled):hover {
        .p-inputswitch-slider {
            -moz-border-radius: 30px;
            -webkit-border-radius: 20px;
            border-radius: 30px;
            border-color: var(--borderLightColor);
            background-color: var(--borderLightColor);

            &:before {
                border-width: 0;
                height: 1.25em !important;
                width: 1.25em !important;
                left: 0.25em;
                bottom: 0.25em;
                -moz-border-radius: 50%;
                -webkit-border-radius: 50%;
                border-radius: 50%;
                background-color: var(--whiteColor);
            }
        }

        &.p-inputswitch-checked,
        &.p-inputswitch-checked:hover {
            .p-inputswitch-slider {
                background-color: var(--actionColor);

                &:before {
                    -moz-border-radius: 50%;
                    -webkit-border-radius: 50%;
                    border-radius: 50%;
                    background-color: var(--whiteColor);
                }
            }

            &.p-inputswitch-focus {
                .p-inputswitch-slider {
                    border-color: var(--borderLightColor);
                }
            }
        }
    }

    .p-accordion .p-accordion-header a {
        border-width: 1px 0;
        border-color: var(--borderLightColor);
        padding: 0.571em;
        background-color: var(--neutralColor);
        color: var(
            --primaryDesaturatedColor
        ); /* TODO: consider adjusting to match topbar background color and see what that does. */
        font-weight: bold;
    }

    .p-accordion {
        .p-accordion-header {
            margin: 0.571em 0 0;

            > a {
                border-width: 1px 0;
                border-color: var(--borderLightColor);
                padding: 0.571em;
                background-color: var(--neutralColor);
                color: var(--blackColor);
                font-weight: bold;

                .p-accordion-toggle-icon {
                    color: var(--primaryDesaturatedColor);
                }
            }

            &:not(.p-disabled) {
                &.p-highlight,
                &:not(.p-highlight) {
                    > a {
                        border-width: 1px 0;
                        border-color: var(--borderLightColor);
                        background-color: var(--contextBGColor);
                        color: var(--blackColor);

                        .p-accordion-toggle-icon {
                            color: var(--primaryColor);
                        }
                    }

                    &:hover {
                        > a {
                            border-width: 1px 0;
                            border-color: rgba(0, 0, 0, 0.568);
                            background-color: var(--contextBGColor);
                            color: var(--primaryDesaturatedColor);

                            .p-accordion-toggle-icon {
                                color: var(--primaryColor);
                            }
                        }
                    }
                }
            }
        }

        .p-accordion-content {
            border-width: 0 1px 1px;
            border-color: var(--borderLightColor);
        }
    }

    .p-toast {
        .p-toast-message {
            a {
                font-weight: bold;
            }

            &.p-toast-message-info {
                background-color: var(--infoBGColor);
                border: 0 none;
                color: #212121;
            }

            &.p-toast-message-success {
                background-color: var(--successBGColor);
                border: 0 none;
                color: #212121;

                a {
                    color: var(--successColor);
                }
            }

            &.p-toast-message-warn {
                background-color: var(--warnBGColor);
                border: 0 none;
                color: #212121;

                a {
                    color: var(--warnColor);
                }
            }

            &.p-toast-message-error {
                background-color: var(--errorBGColor);
                border: 0 none;
                color: #212121;

                a {
                    color: var(--errorColor);
                }
            }

            .p-toast-message-content {
                padding: 0.5em 1em;

                .p-toast-message-icon {
                    position: absolute;
                    display: inline-block;
                    left: 0.5em;
                    top: 0.25em;
                    padding: 0;
                    font-size: 2em;
                }

                .p-toast-message-text {
                    padding: 0 0 0.25em;
                    margin-left: 2.5em;

                    .p-toast-summary {
                        font-weight: 700;
                        padding: 0 0 0.5em;
                        display: block;
                    }
                }
            }
        }

        /* support \n (newline) in toast messages */
        .p-toast-detail {
            white-space: pre-line;
        }
    }

    .p-message {
        display: flex;
        align-items: center;

        .p-message-icon {
            flex: 0 0 auto;
            margin-right: 0.429em;
        }

        a {
            font-weight: bold;
        }

        &.p-message-info {
            background-color: var(--infoBGColor);
            border: 1px solid var(--infoColor);
            color: var(--textColor);
        }

        &.p-message-success {
            background-color: var(--successBGColor);
            border: 1px solid var(--successColor);
            color: var(--textColor);

            a {
                color: var(--successColor);
            }
        }

        &.p-message-warn {
            background-color: var(--warnBGColor);
            border: 1px solid var(--warnColor);
            color: var(--textColor);

            a {
                color: var(--warnColor);
            }
        }

        &.p-message-error {
            background-color: var(--errorBGColor);
            border: 1px solid var(--errorColor);
            color: var(--textColor);

            a {
                color: var(--errorColor);
            }
        }
    }

    p-message[severity='info'] .p-inline-message {
        background-color: var(--infoBGColor);
        border: 1px solid var(--borderLightColor);
        color: var(--textColor);
    }

    p-message[severity='success'] .p-inline-message {
        background-color: var(--successBGColor);
        border: 1px solid var(--borderLightColor);
        color: var(--textColor);
    }

    p-message[severity='warn'] .p-inline-message {
        background-color: var(--warnBGColor);
        border: 1px solid var(--borderLightColor);
        color: var(--textColor);
    }

    .p-inline-message {
        display: flex;
        align-items: center;

        .p-inline-message-icon {
            flex: 0 0 auto;
            margin-right: 0.429em;
        }

        a {
            font-weight: bold;
        }

        &.p-inline-message-success {
            a {
                color: var(--successColor);
            }
        }

        &.p-inline-message-warn {
            a {
                color: var(--warnColor);
            }
        }

        &.p-inline-message-error {
            background-color: var(--errorBGColor);
            border: 1px solid var(--borderLightColor);
            color: var(--textColor);

            a {
                color: var(--errorColor);
            }
        }
    }
}

body p-messages {
    display: block;
    border: 0;
    padding: 0;
}

body p-messages .p-message {
    border: 1px solid;
    margin: 0.5em 0;
    padding: 1em 1em 1em 0.5em;
}

body .p-inline-message .p-inline-message-icon {
    display: none;
}

.login-body {
    background-image: url('/assets/img/cityscene1-min.jpg');
    background-position: 50% 50%;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: stretch;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;

    app-login {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}

.m-exception {
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;

    .p-grid {
        margin: 0;
        width: 60%;
        align-items: stretch;
        align-self: center;
        justify-self: center;

        .m-exception-logo {
            background-color: var(--primarySemitransparentColor);
            text-align: center;
            padding: 40px 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 70%;
            }
        }

        .m-exception-content {
            background-color: var(--counterLogoBGColor);
            padding: 40px;
            color: var(--whiteColor);

            .m-exception-controls {
                text-align: center;
                padding: 2em 0;
            }
        }
    }

    &.offline {
        background-image: url('/assets/img/breakdown-min.jpg');
    }

    &.notfound {
        background-image: url('/assets/img/desert-min.jpg');
    }
}

h3 {
    color: var(--primaryDesaturatedColor);
    background-color: var(--neutralColor);
    padding: 0.4em 0.5em;
    margin: 0 0 0.48em;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.t-alignstart {
        justify-content: flex-start;

        > * {
            margin-right: 0.5em;
        }
    }
}

hr {
    border: 1px solid var(--primaryDesaturatedColor);
    margin: 1.571em 0;
}

.bordered-panel {
    border: 1px solid var(--borderLightColor);
    border-radius: 2px;
    padding: 0.2em 0.4em;
}

div[role*='field'] {
    padding: 0.5em 0.25em;
}

//trying to remove this everywhere
button.rbBtn-small,
.rbBtn-small .p-button {
    padding: 0em 0.1em;
    font-size: 75%;
}

//trying to remove this everywhere
.inline-plus-button {
    position: absolute;
    top: 0;
    right: 0;
}

.centered-cell {
    text-align: center;
}

.right-aligned-cell {
    text-align: right;
}

.layout-wrapper.layout-light.layout-slim {
    .layout-main {
        .layout-topbar {
            background-color: var(--top-bar-background-desaturated);
            background-image: -webkit-gradient(
                linear,
                left top,
                right top,
                from(var(--top-bar-background-desaturated)),
                to(var(--primaryLightColor))
            );
            background-image: -webkit-linear-gradient(
                45deg,
                var(--top-bar-background-desaturated),
                var(--top-bar-background-light-desaturated)
            );
            background-image: -moz-linear-gradient(
                45deg,
                var(--top-bar-background-desaturated),
                var(--top-bar-background-light-desaturated)
            );
            background-image: -ms-linear-gradient(
                45deg,
                var(--top-bar-background-desaturated),
                var(--top-bar-background-light-desaturated)
            );
            background-image: -o-linear-gradient(
                45deg,
                var(--top-bar-background-desaturated),
                var(--top-bar-background-light-desaturated)
            );
            background-image: linear-gradient(
                45deg,
                var(--top-bar-background-desaturated),
                var(--top-bar-background-light-desaturated)
            );
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="var(--top-bar-background-desaturated)", endColorstr="var(--top-bar-background-light-desaturated)", gradientType="1");
            display: flex;
            align-items: center;

            .menu-btn {
                color: var(--whiteColor);

                &:hover {
                    color: var(--actionColor);
                }
            }

            .layout-topbar-menu-wrapper {
                .topbar-menu-btn {
                    color: var(--bright-text-color);
                    font-size: 24px;
                    top: auto;
                    vertical-align: middle;

                    i {
                        color: var(--bright-text-color);

                        &:hover {
                            color: var(--actionColor);
                        }
                    }
                }

                .topbar-menu {
                    & > li {
                        & > a {
                            width: 100%;
                            display: inline-block;

                            i {
                                color: var(--textColor);
                                margin-right: 0.4em;

                                &:hover {
                                    color: var(--actionColor);
                                }
                            }

                            .topbar-badge {
                                position: absolute;
                                top: 0px;

                                left: 32px;

                                &.s-badgeAlert {
                                    background-color: var(--errorColor);
                                    color: var(--whiteColor);
                                }
                            }
                        }

                        &.active-topmenuitem {
                            border-top: 1px solid var(--borderLightColor);

                            & > a {
                                background-color: var(--selectedColor);
                                border-bottom: 1px solid var(--borderLightColor);

                                i {
                                    color: var(--actionColor);
                                }
                            }

                            & > ul {
                                border-bottom: 1px solid var(--borderLightColor);

                                & > li {
                                    & > ul {
                                        display: none;
                                        -webkit-animation-duration: 0.5s;
                                        -moz-animation-duration: 0.5s;
                                        animation-duration: 0.5s;
                                        list-style-type: none;
                                        margin: 0 0 0 1em;
                                        padding: 8px 0;
                                        background-color: var(--layout-wrapper-background);
                                    }

                                    &.active-submenuitem {
                                        border-top: 1px solid var(--borderLightColor);

                                        & > a {
                                            background-color: var(--selectedColor);
                                            border-bottom: 1px solid var(--borderLightColor);

                                            i {
                                                color: var(--actionColor);
                                            }
                                        }

                                        & > ul {
                                            border-bottom: 1px solid var(--borderLightColor);
                                            display: block;
                                        }
                                    }
                                }
                            }

                            .topbar-menu-item-header {
                                border-width: 1px 0px;
                                border-style: solid;
                                padding: 8px 14px;
                                font-weight: bold;

                                margin: 4px 0px;

                                &.s-warn {
                                    border-color: var(--warnColor);
                                    background-color: var(--topMenuWarnBGColor);
                                    color: var(--warnColor);
                                }

                                &.s-alert {
                                    border-color: var(--errorColor);
                                    background-color: var(--topMenuAlertBGColor);
                                    color: var(--errorColor);
                                }
                            }
                        }

                        &.profile-item {
                            ul {
                                right: 0;
                            }

                            > a {
                                top: auto;

                                .topbar-badge {
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                }
            }

            .topbar-search {
                input {
                    color: var(--whiteColor);
                    border-bottom: 1px solid var(--whiteColor);
                    width: 250px;
                }

                i {
                    color: var(--whiteColor);
                }
            }
        }

        .rightpanel-btn {
            margin-top: 0;

            i {
                color: var(--whiteColor);

                &:hover {
                    color: var(--actionColor);
                }
            }
        }

        .layout-content {
            min-height: calc(100vh - 27px);
            padding-bottom: 6px;
        }
    }

    .layout-sidebar {
        .layout-menu-container {
            overflow: hidden;

            .layout-menu {
                li {
                    > a {
                        width: 270px;
                        display: flex;
                        padding: 8px 0;
                        align-items: center;

                        i:first-child {
                            background-color: transparent;
                            font-size: 24px;
                            flex: 0 0 70px;
                        }

                        &:hover {
                            background-color: var(--selectedColor);

                            i:first-child {
                                color: var(--primaryLightColor);
                            }
                        }

                        span {
                            margin-top: 0;
                            flex: 1 1 auto;
                        }

                        .layout-submenu-toggler {
                            margin: 0;
                            flex: 0 0 55px;
                        }
                    }

                    > ul {
                        border-left: 4px solid var(--primaryLightColor);
                    }
                }
            }
        }

        .layout-sidebar-logo {
            background-color: var(--primaryDarkColor);
            background-image: -webkit-gradient(
                linear,
                left top,
                right top,
                from(var(--top-bar-background-desaturated)),
                to(var(--primaryDarkColor))
            );
            background-image: -webkit-linear-gradient(
                45deg,
                var(--top-bar-background-desaturated),
                var(--top-bar-background-light-desaturated)
            );
            background-image: -moz-linear-gradient(
                45deg,
                var(--top-bar-background-desaturated),
                var(--top-bar-background-light-desaturated)
            );
            background-image: -ms-linear-gradient(
                45deg,
                var(--top-bar-background-desaturated),
                var(--top-bar-background-light-desaturated)
            );
            background-image: -o-linear-gradient(
                45deg,
                var(--top-bar-background-desaturated),
                var(--top-bar-background-light-desaturated)
            );
            background-image: linear-gradient(
                45deg,
                var(--top-bar-background-desaturated),
                var(--top-bar-background-light-desaturated)
            );
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="var(--top-bar-background-desaturated)", endColorstr="var(--top-bar-background-light-desaturated)", gradientType="1");
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0;
            width: 270px;

            .layout-sidebar-anchor {
                margin-top: 5px;
                flex: 0 0 55px;

                i {
                    transition: all 0.25s;

                    &:before {
                        content: '\2770';
                    }
                }
            }

            a {
                flex: 0 0 70px;
                padding-top: 14px;
                padding-bottom: 7px;
                text-align: center;
            }

            img {
                height: 32px;
                float: none;
            }

            &:after {
                display: none;
            }
        }
    }

    &.layout-slim-anchored {
        .layout-sidebar {
            .layout-sidebar-logo {
                .layout-sidebar-coverage-return,
                .layout-sidebar-coverage-select {
                    i {
                        color: var(--whiteColor);
                    }
                }

                .layout-sidebar-anchor {
                    i {
                        transform: rotate(-180deg);
                        color: var(--actionColor);

                        &:before {
                            content: '\2770';
                        }
                    }
                }
            }

            .layout-menu-container {
                overflow-y: auto;
            }
        }
    }

    &.layout-slim-active {
        .layout-sidebar {
            .layout-menu-container {
                overflow-y: auto;
            }
        }
    }
}

@media (min-width: 1281px) {
    .layout-wrapper.layout-light.layout-slim {
        &.layout-slim-anchored,
        &.layout-slim-active {
            .layout-sidebar {
                width: 270px;
                left: auto;
            }
        }

        .layout-sidebar {
            width: 70px;
            overflow-x: hidden;
            transition: width 0.3s;
            left: auto;
        }

        .layout-main {
            .layout-topbar {
                .layout-topbar-menu-wrapper {
                    .topbar-menu {
                        & > li {
                            & > a {
                                color: var(--bright-text-color);

                                i {
                                    color: var(--bright-text-color);
                                    margin-right: 0;

                                    &:hover {
                                        color: var(--actionColor);
                                    }
                                }

                                .topbar-badge {
                                    top: -7px;

                                    left: 18px;
                                }
                            }

                            &.active-topmenuitem {
                                border-top-width: 0;

                                & > a {
                                    background-color: transparent;
                                    border-bottom-width: 0;

                                    i {
                                        color: var(--actionColor);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.p-grid {
    margin-left: -1em;
    margin-right: -1em;
    margin-top: 0;

    &.p-nogutter {
        margin-right: 0;
        margin-left: 0;
        margin-top: 0;
    }

    & > .p-col,
    & > [class*='p-col'],
    & > [class*='p-xl'],
    & > [class*='p-lg'],
    & > [class*='p-md'],
    & > [class*='p-sm'] {
        padding: 0 1em;
    }
}

.m-contextBar-wrapper {
    padding-top: 58px;
}

.m-contextBar-wrapper.s-condensed {
    padding-top: 36px;
}

.m-module {
    padding: 0 0 1.25em;

    .p-panel {
        .p-panel-titlebar {
            border-bottom-width: 0;
        }

        .p-panel-content {
            border-top: 1px solid var(--lightGrayColor);
            padding: 1em;
        }
    }

    &.m-module-error {
        h3 {
            background-color: var(--errorBGColor);
            color: var(--errorColor);
        }
    }

    &.m-module-warn {
        h3 {
            background-color: var(--warnBGColor);
            color: var(--warnColor);
        }
    }
}

body {
    .p-overlaypanel {
        .p-overlaypanel-close {
            top: 0;
            right: 0;
        }
    }
}

.p-overlaypanel-content {
    .m-module {
        padding: 0;
    }
}

.m-module-controls {
    border-bottom: 1px solid var(--borderLightColor);
    margin: -1em -14px 1.5em;
    padding: 15px 1em;
    background-color: var(--infoBGColor);

    button {
        margin-right: 1em;
    }
}

.m-instructions,
.m-summary {
    background-color: var(--infoBGColor);
    color: var(--darkGrayColor);
    margin-bottom: 1.5em;
    border: 1px solid var(--borderLightColor);
    display: flex;
    align-items: center;

    p-message {
        border: 0;
        padding: 0;

        .p-inline-message {
            padding: 1em;
            border-width: 0px 1px 0px 0px !important;
        }
    }

    div[role='gridcell'] {
        padding: 1em;

        &:not(:last-of-type) {
            border-right: 1px solid var(--borderLightColor);
        }

        .m-summary-label {
            font-weight: bold;
            padding-left: 0.25em;
        }

        .m-summary-value {
            padding-right: 0.25em;
        }
    }
}

.m-instructions {
    padding: 1em;
}

.m-summary {
    padding: 0;
}

.m-controlMsg {
    color: var(--errorColor);
    padding: 1px 0.5em 0;
    font-size: 0.86em;
}

.m-records {
    &.p-dataview {
        .p-dataview-content {
            border-top-width: 0;
            padding: 0;

            .p-dataview-emptymessage {
                min-height: 65px;
                flex: 1 1 auto;
                margin: 1em;
                text-align: center;
                padding: 20px;
                background-color: var(--warnBGColor);
                color: var(--warnColor);
            }
        }

        .p-button {
            margin-left: 1em;
        }
    }
}

.m-records-header {
    display: flex;
    align-items: center;

    .m-records-header-spacer {
        flex: 1 1 auto;
    }

    .m-records-header-tools {
        flex: 0 1 250px;
        text-align: right;
    }
}

.m-record {
    display: flex;
    flex: 1 1 100%;
    align-items: stretch;
    border-bottom: 1px solid var(--borderLightColor);
    padding: 0.571em 1.25em;

    &:hover {
        background-color: var(--listRecordHoverColor);
        cursor: pointer;
    }

    &:last-child {
        border-bottom-width: 0;
    }
}

.m-record-data {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    flex: 1 1 0;

    & > div {
        flex: 0 1 33%;
        padding: 0px 15px 0px 0px;
        justify-content: flex-start;
        align-items: baseline;

        & > div {
            padding: 4px 0;
        }
    }

    .m-record-primary {
        font-weight: bold;
        font-size: 1rem;
        padding: 2px 0 3px;
        flex: 0 1 33%;
    }

    .m-record-address {
        flex: 0 1 33%;
    }
}

.m-record-id {
    flex: 0 0 8em;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.m-record-id,
.m-record-actions {
    padding: 0;
    margin: 0 0.25em;
}

.m-record-list-id {
    padding: 0.25em;
}

.m-record-status {
    border: 1px solid var(--lightGrayColor);
    padding: 3px 8px;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0.05);
    align-self: stretch;
    text-align: center;
    color: var(--darkGrayColor);

    &.s-success {
        border-color: var(--successColor);
        background-color: var(--successBGColor);
        color: var(--successColor);
    }

    &.s-alert {
        border-color: var(--errorColor);
        background-color: var(--errorBGColor);
        color: var(--errorColor);
    }

    &.s-warn {
        border-color: var(--warnColor);
        background-color: var(--warnBGColor);
        color: var(--warnColor);
    }
}

@media (min-width: 800px) {
    .m-record-id,
    .m-record-actions {
        margin-left: 0.5em;
        margin-right: 0.5em;
    }
}

@media (min-width: 1000px) {
    .m-record-id {
        margin-left: 0.75em;
        margin-right: 1em;
    }

    .m-record-actions {
        margin-left: 1em;
        margin-right: 0.75em;
    }
}

@media (min-width: 1200px) {
    .m-record-id {
        margin-left: 1em;
        margin-right: 2em;
    }

    .m-record-actions {
        margin-left: 2em;
        margin-right: 1em;
    }
}

@media (min-width: 1350px) {
    .m-record-id {
        margin-right: 4em;
    }

    .m-record-actions {
        margin-left: 4em;
    }
}

.m-record-actions {
    flex: 0 0 auto;
    align-items: center;
    display: flex;
    padding-left: 15px;
}

.s-record-field-empty {
    color: var(--darkGrayColor);
}

.m-table-header {
    border: 1px solid var(--borderLightColor);
    color: var(--textColor);
    background-color: var(--contextBGColor);
    padding: 0.571em 1em;

    border-bottom: 0 none;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
        flex: 0 1 auto;
        padding-right: 2em;
    }

    .m-table-header-title {
        display: flex;
        align-items: center;

        > span {
            flex: 0 0 auto;
            font-size: 1.1em;
            padding-right: 2em;
            font-weight: 700;
        }

        > button {
            flex: 0 0 auto;
        }
    }

    .m-table-header-tools {
        text-align: right;
        padding-right: 0;
        display: flex;
        flex-wrap: wrap;
        flex: 0 1 60%;
        justify-content: space-between;

        > button,
        > div {
            margin: 0 0.5em;
            flex: 0 0 auto;
            order: 1;

            label {
                margin-right: 0.5em;
            }
        }

        .m-table-header-search {
            position: relative;
            order: 0;
            flex: 0 0 95%;

            > i:first-of-type {
                position: absolute;
                top: 6px;
                left: 6px;
            }

            > input {
                padding-left: 26px;
                width: 100%;
                margin-bottom: 0.571em;
            }
        }
    }
}

@media (min-width: 565px) {
    .m-table-header {
        .m-table-header-tools {
            flex: 0 0 auto;

            .m-table-header-search {
                order: 1;
                flex: 0 0 auto;

                input {
                    margin-bottom: 0em;
                }
            }
        }
    }
}

.m-datagrid-actions {
    text-align: center;

    button {
        margin: 0 0.5em;
    }
}

.m-filters {
    .m-filters-fields {
        display: flex;
        flex-wrap: wrap;

        > div {
            flex: 0 1 25%;
        }
    }

    .p-accordion {
        .p-accordion-content {
            border-width: 0px;
            padding: 0.5em 0;
        }
    }
}

@media screen and (min-width: 992px) {
    .m-filters {
        .m-filters-fields {
            > div {
                flex-basis: 100%;
                flex-wrap: nowrap;
            }
        }
    }
}

.m-xray-table .p-datatable {
    border: 1px solid var(--borderLightColor);
    margin-bottom: 1em;
}

.m-xray-field-item {
    margin-bottom: 0.571em;
}

// fix for missing p-menu from p-button
div.p-menu.p-menu-dynamic.m-client-trips-between {
    display: block;
}

.p-picklist.p-picklist-responsive .p-picklist-listwrapper.p-picklist-listwrapper-nocontrols {
    width: 40%;
}

.p-picklist.p-picklist-responsive .p-picklist-listwrapper {
    width: 40%;
}

body .p-picklist .p-picklist-buttons {
    height: initial;
    display: flex;
}

.m-search {
    .m-search-getall {
        text-align: right;
        padding-right: 1em;
        padding-bottom: 1em;
    }
}

.p-dropdown-item .s-lookup-highlight,
.p-dropdown-label .s-lookup-highlight {
    font-weight: bold;
}

.layout-wrapper .layout-sidebar .layout-menu-container .layout-menu.layout-menu {
    padding-bottom: 0;
}

body .p-button-icon.p-button-icon-left {
    margin-left: 0.5em;
    margin-top: 0.12em;
    position: static;
}

body .p-button-icon.p-button-icon-left ~ .p-button-label {
    padding-left: 1em;
}

body .p-dropdown .p-dropdown-label.p-inputtext {
    padding-right: 0.5em;
}

body .p-multiselect-panel .p-multiselect-header .p-multiselect-close.p-link {
    margin: 0 -0.5em 0 0.5em;
    right: 0;
}
